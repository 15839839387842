import { ReactNode } from 'react';
import { cn } from '@bits/ui/utils/cn';
import { X } from '@phosphor-icons/react';
import * as Dialog from '@radix-ui/react-dialog';

export const ThemedDialog = ({
  children,
  ...props
}: Dialog.DialogProps & { closeButton?: boolean }) => (
  <Dialog.Root {...props}>{children}</Dialog.Root>
);

export const ThemedDialogContent = ({
  children,
  variant = 'lg',
  closeButton = true,
}: {
  children: ReactNode;
  variant?: 'xs' | 'sm' | 'lg' | 'xl' | '2xl' | '3xl' | 'fill';
  closeButton?: boolean;
}) => {
  return (
    <Dialog.Portal>
      <Dialog.DialogOverlay
        className={cn(
          'bg-ink/60 fixed inset-0 z-10 flex overflow-auto p-4 lg:p-8 backdrop-blur-sm',
          'data-[state=open]:animate-in data-[state=open]:fade-in',
          'data-[state=closed]:animate-out data-[state=closed]:fade-out'
        )}
      >
        <Dialog.Content
          className={cn(
            `relative m-auto grid w-full gap-4 rounded-lg
            bg-theme-canvas-backgroundColor text-theme-typography-paragraph-textColor 
            p-6 shadow-[-10px_0px_54px_0px_rgba(0,0,0,0.05)] animate-in 
            slide-in-from-bottom-10 fade-in-0 ease-out
            max-w-3xl
            sm:rounded-xl`,
            variant === 'xs' && 'max-w-md',
            variant === 'sm' && 'max-w-lg',
            variant === 'lg' && 'max-w-2xl',
            variant === 'xl' && 'max-w-3xl',
            variant === '2xl' && 'max-w-4xl',
            variant === '3xl' && 'max-w-5xl',
            variant === 'fill' && 'max-w-full'
          )}
        >
          {children}

          {closeButton && (
            <Dialog.Close asChild>
              <button
                type="button"
                className={cn(
                  'absolute top-3 right-3 rounded-full flex items-center ',
                  'justify-center size-10 bg-theme-buttons-primary-normal-backgroundColor',
                  'text-theme-buttons-primary-normal-textColor',
                  'hover:bg-theme-buttons-primary-hover-backgroundColor'
                )}
              >
                <X className="size-4" weight="bold" />
              </button>
            </Dialog.Close>
          )}
        </Dialog.Content>
      </Dialog.DialogOverlay>
    </Dialog.Portal>
  );
};

export const ThemedDialogClose = Dialog.Close;

export const ThemedDialogTrigger = Dialog.Trigger;
