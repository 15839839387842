import { LayoutGroup, motion } from 'framer-motion';

import { cn } from './utils/cn';

export const SegmentedControl = ({
  options,
  onValueChange,
  value,
  disabled,
  name,
  label,
}: {
  options: { label: string; value: string }[];
  onValueChange: (value: string) => void;
  value: string;
  disabled?: boolean;
  name: string;
  label?: string;
}) => {
  return (
    <div className="flex items-center justify-between gap-2">
      {label && (
        <label
          htmlFor={name}
          id={`${name}-label`}
          className="text-ink bits-text-body-1"
        >
          {label}
        </label>
      )}
      <div
        className={cn(
          'inline-flex bg-fog p-1 gap-1.5 rounded-lg',
          disabled && 'opacity-50'
        )}
        role="radiogroup"
        aria-disabled={disabled}
        aria-labelledby={`${name}-label`}
      >
        <LayoutGroup id={name}>
          {options.map((option) => (
            <button
              role="radio"
              aria-checked={option.value === value}
              key={option.value}
              className={cn(
                'relative bits-text-body-2 px-2 py-1 enabled:hover:bg-white/50 enabled:hover:text-ink enabled:cursor-pointer rounded-lg transition-all',
                option.value === value ? 'text-ink' : 'text-shadow'
              )}
              onClick={() => onValueChange(option.value)}
              disabled={disabled}
            >
              {option.value === value && (
                <motion.span
                  className="absolute inset-0 rounded-lg bg-white shadow-sm"
                  layout
                  layoutId="segmented-control-active-bg"
                  transition={{
                    type: 'spring',
                    duration: 0.2,
                    bounce: 0,
                  }}
                />
              )}
              <span className={cn('relative z-10')}>{option.label}</span>
            </button>
          ))}
        </LayoutGroup>
      </div>
    </div>
  );
};
