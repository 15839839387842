import React, { ButtonHTMLAttributes, ReactNode } from 'react';
import { Slot, Slottable } from '@radix-ui/react-slot';

import { SmallSpinner } from '../small-spinner';
import { cn } from '../utils/cn';

export interface ThemedButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  asChild?: boolean;
  icon?: ReactNode;
  iconPosition?: 'left' | 'right';
  children: ReactNode;
  loading?: boolean;
  variant?: 'primary' | 'outline' | 'link';
}

export const ThemedButton = React.forwardRef<
  HTMLButtonElement,
  ThemedButtonProps
>(
  (
    {
      asChild = false,
      className,
      icon,
      iconPosition = 'left',
      children,
      loading,
      variant = 'primary',
      type = 'button',
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(
          'inline-flex relative justify-center gap-2 items-center transition-all duration-75 overflow-hidden',
          (variant === 'primary' || variant === 'outline') && [
            'p-6 py-3 min-w-[120px]',
            '[letter-spacing:var(--buttons-primary-letterSpacing)] text-theme-buttons-primary-fontSize [text-transform:var(--buttons-primary-uppercase)]',
            '[box-shadow:var(--buttons-primary-shadow)] [font-weight:var(--buttons-primary-fontWeight)]',
            'rounded-[var(--buttons-primary-cornerRadius)]',
          ],
          variant === 'primary' && [
            'bg-theme-buttons-primary-normal-backgroundColor text-theme-buttons-primary-normal-textColor border-theme-buttons-primary-normal-borderColor',
            'hover:text-theme-buttons-primary-hover-textColor hover:bg-theme-buttons-primary-hover-backgroundColor hover:border-theme-buttons-primary-hover-borderColor',
            'active:bg-theme-buttons-primary-hover-backgroundColor/50 active:transition-none [border-width:var(--buttons-primary-borderWidth)]',
          ],
          variant === 'outline' && [
            'bg-transparent',
            'border-2 border-theme-general-dividerColor',
            'text-theme-typography-paragraph-textColor',
            'hover:bg-theme-typography-paragraph-textColor/5',
          ],
          variant === 'link' && [
            'text-theme-typography-paragraph-fontSize underline hover:opacity-70',
          ],
          'aria-disabled:opacity-50 aria-disabled:pointer-events-none text-pretty text-center',
          'outline-none focus-visible:outline-2 focus-visible:outline-theme-general-focusColor',
          '[&_svg]:size-4',
          className
        )}
        type={type}
        {...props}
        disabled={props.disabled || loading}
        aria-disabled={props.disabled || loading}
        ref={ref}
      >
        {iconPosition === 'left' && icon}
        <Slottable>{children}</Slottable>
        {iconPosition === 'right' && icon}
        {loading && (
          <div className="bg-theme-buttons-primary-normal-backgroundColor absolute inset-0 flex">
            <SmallSpinner className="m-auto" />
          </div>
        )}
      </Comp>
    );
  }
);
ThemedButton.displayName = 'ThemedButton';
