'use client';

import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { CaretUpDown, Check } from '@phosphor-icons/react';
import { useVirtualizer } from '@tanstack/react-virtual';
import clsx from 'clsx';

import { Popover, PopoverPrimitive, PopoverTrigger } from '../popover';
import { cn } from '../utils/cn';
import { useIsHandheld } from '../utils/use-browser';
import { useUuid } from '../utils/use-uuid';
import {
  Command,
  CommandEmpty,
  CommandInput,
  CommandItem,
} from './themed-command';

export type SearchableSelectProps<
  TOptionData,
  TOption = { label: string; value: string; data?: TOptionData },
> = {
  options: TOption[];
  multiselect?: boolean;
  values?: string[];
  error?: string | boolean;
  label?: string;
  hint?: string;
  onValuesChange?: (value: string[]) => void;
  renderOption?: (option: TOption, values: string[]) => ReactNode;
  renderValues?: (values: string[], options: TOption[]) => ReactNode;
  placeholder: string;
  emptyText: string;
  searchable?: boolean;
  required?: boolean;
  disabled?: boolean;
  optional?: string;
};

/**
 * A controlled searchable select component, combobox.
 * Uses a popover to display options.
 * For handheld devices, it uses a native select, which is better suited.
 *
 * @example
 * const [values, setValues] = useState<string[]>(['option1']);
 * ...
 * <SearchableSelect
 *   values={values}
 *   options={[
 *     { label: 'Option 1', value: 'option1' },
 *     { label: 'Option 2', value: 'option2' },
 *     { label: 'Option 3', value: 'option3' },
 *   ]}
 *   multiselect
 *   placeholder="Select options"
 *   emptyText="No options found"
 *   onValuesChange={setValues}
 * />
 */
export function ThemedSearchableSelect<
  TOptionData extends Record<string, unknown>,
  TOption extends { label: string; value: string; data?: TOptionData },
>({
  options,
  multiselect,
  values = [],
  error,
  label,
  hint,
  onValuesChange,
  renderOption: _renderOption,
  renderValues: _renderValues,
  searchable = true,
  placeholder,
  emptyText,
  required,
  disabled,
  optional,
}: SearchableSelectProps<TOptionData>) {
  const [open, setOpen] = useState(false);

  const renderOption = useMemo(
    () =>
      _renderOption
        ? _renderOption
        : (option: TOption, values: string[]) => {
            return (
              <>
                <span
                  className={clsx(
                    'mr-2 flex size-5 shrink-0 items-center justify-center rounded-md',
                    multiselect && [
                      'border-theme-inputs-checkboxesAndRadios-unchecked-borderColor',
                      '[border-width:max(var(--inputs-borderWidth,1px),1px)]',
                      'text-theme-inputs-checkboxesAndRadios-indicatorColor',
                      values.includes(option.value)
                        ? 'bg-theme-inputs-checkboxesAndRadios-checked-backgroundColor'
                        : 'bg-theme-inputs-checkboxesAndRadios-unchecked-backgroundColor',
                    ],
                    !multiselect && ['text-theme-inputs-textColor']
                  )}
                >
                  <Check
                    weight="bold"
                    className={cn(
                      'h-4 w-4',
                      values.includes(option.value)
                        ? 'opacity-100'
                        : 'opacity-0'
                    )}
                  />
                </span>
                <span title={option.label}>{option.label}</span>
              </>
            );
          },
    [_renderOption, multiselect]
  );

  const renderValues = useMemo(
    () =>
      _renderValues
        ? _renderValues
        : (values: string[], options: TOption[]) => {
            return values
              .map((val) => options.find((i) => i.value === val)?.label ?? '')
              .filter(Boolean)
              .map((val) => (
                <div
                  className="not-first:mt-1 not-first:border-t border-theme-inputs-borderColor not-first:pt-1 w-full border-dashed"
                  key={val}
                  title={val}
                >
                  {val}
                </div>
              ));
          },
    [_renderValues]
  );

  useEffect(() => {
    if (open) setFilterValue('');
  }, [open]);

  const contentId = useUuid();
  const errorId = useUuid();
  const hintId = useUuid();
  const labelId = useUuid();

  const [filterValue, setFilterValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const filteredOptions = useMemo(
    () =>
      options.filter(
        (option) =>
          option.label.toLowerCase().includes(filterValue.toLowerCase()) ||
          option.value.toLowerCase().includes(filterValue.toLowerCase())
      ),
    [options, filterValue]
  );

  const shouldUseNativeSelect = useIsHandheld();

  const listRef = useRef<HTMLDivElement>(null);
  const virtualizer = useVirtualizer({
    count: filteredOptions.length,
    getScrollElement: () => listRef.current,
    estimateSize: () => 40,
  });

  const renderedItems = virtualizer.getVirtualItems();

  useEffect(() => {
    if (open) {
      setTimeout(() => virtualizer.measure());
    }
  }, [open]);

  return (
    <div className="w-full overflow-hidden">
      {label && (
        <p
          className={cn(
            'text-theme-inputs-labelFontSize text-theme-inputs-labelTextColor mb-2',
            error && 'text-theme-inputs-errorColor'
          )}
          id={labelId}
        >
          {label} {optional && `(${optional})`}
        </p>
      )}

      {shouldUseNativeSelect && (
        <div
          className={clsx(
            'bits-text-body-1 relative flex min-h-11 w-full items-center justify-between px-3 py-2 text-left transition-colors ',
            'text-theme-inputs-normal-textColor border-theme-inputs-borderColor bg-theme-inputs-backgroundColor',
            'leading-normal [border-radius:var(--inputs-cornerRadius)] [border-width:var(--inputs-borderWidth)]',
            'disabled:cursor-not-allowed disabled:bg-[color-mix(in_srgb,var(--inputs-textColor)_10%,var(--inputs-backgroundColor))] disabled:opacity-75',
            'focus-visible:border-theme-general-focusColor focus-visible:outline-theme-general-focusColor outline-none outline-offset-0 focus-visible:outline-1',
            'text-theme-inputs-textColor',
            'hover:bg-[color-mix(in_srgb,var(--inputs-textColor)_5%,var(--inputs-backgroundColor))]',
            error &&
              'border-theme-inputs-errorColor focus-visible:!outline-theme-inputs-errorColor'
          )}
        >
          <div className="overflow-hidden">
            {values.length
              ? renderValues(values, options as TOption[])
              : placeholder}
          </div>
          <CaretUpDown className="ml-2 size-4 shrink-0 opacity-50" />
          <select
            className="absolute inset-0 size-full cursor-pointer opacity-0"
            required={required}
            multiple={multiselect}
            value={values}
            disabled={disabled}
            onChange={(e) => {
              const selectedValues = Array.from(
                e.target.selectedOptions,
                (option) => option.value
              );
              onValuesChange?.(selectedValues);
            }}
          >
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      )}

      {!shouldUseNativeSelect && (
        <Popover open={open} onOpenChange={setOpen}>
          <PopoverTrigger asChild>
            <button
              role="combobox"
              aria-required={!!required}
              aria-expanded={open}
              aria-invalid={!!error}
              aria-describedby={error ? errorId : hintId}
              aria-controls={contentId}
              aria-labelledby={labelId}
              disabled={disabled}
              className={clsx(
                'bits-text-body-1 flex min-h-11 w-full items-center justify-between px-3 py-2 text-left transition-colors ',
                'text-theme-inputs-normal-textColor border-theme-inputs-borderColor bg-theme-inputs-backgroundColor',
                'leading-normal [border-radius:var(--inputs-cornerRadius)] [border-width:var(--inputs-borderWidth)]',
                'disabled:cursor-not-allowed disabled:bg-[color-mix(in_srgb,var(--inputs-textColor)_10%,var(--inputs-backgroundColor))] disabled:opacity-75',
                'focus-visible:border-theme-general-focusColor focus-visible:outline-theme-general-focusColor outline-none outline-offset-0 focus-visible:outline-1',
                'text-theme-inputs-textColor',
                error &&
                  'border-theme-inputs-errorColor focus-visible:!outline-theme-inputs-errorColor',
                'hover:bg-[color-mix(in_srgb,var(--inputs-textColor)_5%,var(--inputs-backgroundColor))]'
              )}
            >
              <div className="w-full overflow-hidden">
                {values.length
                  ? renderValues(values, options as TOption[])
                  : placeholder}
              </div>
              <CaretUpDown className="ml-2 size-4 shrink-0 opacity-50" />
            </button>
          </PopoverTrigger>
          <PopoverPrimitive.Portal>
            <PopoverPrimitive.Content
              align="start"
              className={cn(
                'relative overflow-hidden z-10 flex flex-col',
                'animate-in fade-in-0 data-[side=bottom]:mt-1 data-[side=top]:mb-1',
                'data-[side=bottom]:slide-in-from-top-2 data-[side=top]:slide-in-from-bottom-2',
                'p-0 border-theme-inputs-borderColor [border-width:var(--inputs-borderWidth)]',
                'min-w-[var(--radix-popover-trigger-width)] max-w-[calc(var(--radix-popper-available-width)-16px)] max-h-[calc(var(--radix-popper-available-height)-16px)]'
              )}
              id={contentId}
              asChild
            >
              <Command
                shouldFilter={false}
                className="relative h-full overflow-hidden"
              >
                {searchable && (
                  <>
                    <CommandInput
                      disabled={!searchable}
                      placeholder={placeholder}
                      ref={inputRef}
                      value={filterValue}
                      onValueChange={setFilterValue}
                    />
                    <CommandEmpty>{emptyText}</CommandEmpty>
                  </>
                )}
                <div ref={listRef} className="relative h-full overflow-auto">
                  <div
                    style={{
                      height: `${virtualizer.getTotalSize()}px`,
                    }}
                  >
                    {renderedItems.map((row) => {
                      const item = filteredOptions[row.index]!;

                      return (
                        <div
                          style={{
                            width: '100%',
                            transform: `translateY(${row.start}px)`,
                          }}
                          key={row.key as number}
                        >
                          <CommandItem
                            data-index={row.index}
                            key={item.label}
                            value={item.label}
                            className="bg-theme-inputs-backgroundColor text-theme-inputs-textColor"
                            ref={virtualizer.measureElement}
                            onSelect={() => {
                              if (multiselect) {
                                if (values.includes(item.value)) {
                                  onValuesChange?.(
                                    values.filter(
                                      (value) => value !== item.value
                                    )
                                  );
                                } else {
                                  const newValues = [...values, item.value];
                                  onValuesChange?.(newValues);
                                }
                              } else {
                                onValuesChange?.([item.value]);
                                setOpen(false);
                              }
                            }}
                          >
                            {renderOption(item as TOption, values)}
                          </CommandItem>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Command>
            </PopoverPrimitive.Content>
          </PopoverPrimitive.Portal>
        </Popover>
      )}
      {hint && (
        <p
          className={cn(
            'text-theme-inputs-hintTextColor text-theme-inputs-hintAndErrorFontSize mt-1'
          )}
          id={hintId}
        >
          {hint}
        </p>
      )}
      {error && (
        <p
          className={cn(
            'text-theme-inputs-hintTextColor text-theme-inputs-hintAndErrorFontSize mt-1',
            'text-theme-inputs-errorColor'
          )}
          id={errorId}
        >
          {error}
        </p>
      )}
    </div>
  );
}
